import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, NgModule } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import * as i1 from '@angular/common/http';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from '@angular/common';
const CONFIG_URL = new InjectionToken('aviatar-client-config-url');
const APP_CONFIG = new InjectionToken('aviatar-client-config');
// @dynamic
class ConfigurationService {
  static loadConfiguration(configurationService) {
    return () => configurationService.loadSettings();
  }
  static provideConfigurationValue(key) {
    return configurationService => configurationService.settings[key];
  }
  static provideConfigurationObject(spec) {
    return configurationService => {
      const object = {};
      spec.forEach((value, key) => {
        object[key] = configurationService.settings[value];
      });
      return object;
    };
  }
  constructor(http, settings, configUrl) {
    this.http = http;
    this.configUrl = '/config/aviatar-client.json';
    this.settings = {};
    if (configUrl) {
      this.configUrl = configUrl;
    }
    if (settings) {
      this.settings = settings;
    }
  }
  async loadSettings() {
    try {
      this.settings = await firstValueFrom(this.http.get(this.configUrl));
    } catch (e) {
      console.log(`cannot load settings from url ${this.configUrl}`, e);
    }
    return this.settings;
  }
  static {
    this.ɵfac = function ConfigurationService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ConfigurationService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(APP_CONFIG), i0.ɵɵinject(CONFIG_URL));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ConfigurationService,
      factory: ConfigurationService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConfigurationService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [APP_CONFIG]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CONFIG_URL]
    }]
  }], null);
})();
const CUSTOM_CONFIG_URL = new InjectionToken('aviatar-client-custom-config-url');
const CUSTOM_APP_CONFIG = new InjectionToken('aviatar-client-custom-config');
// @dynamic
class CustomConfigurationService {
  static loadConfiguration(configurationService) {
    return () => configurationService.loadSettings();
  }
  static provideConfigurationValue(key) {
    return configurationService => configurationService.settings[key];
  }
  static provideConfigurationObject(spec) {
    return configurationService => {
      const object = {};
      spec.forEach((value, key) => {
        object[key] = configurationService.settings[value];
      });
      return object;
    };
  }
  constructor(http, settings, configUrl) {
    this.http = http;
    this.configUrl = '/config/custom-config.json';
    this.settings = {};
    if (configUrl) {
      this.configUrl = configUrl;
    }
    if (settings) {
      this.settings = settings;
    }
  }
  async loadSettings() {
    try {
      this.settings = await firstValueFrom(this.http.get(this.configUrl));
    } catch (e) {
      console.log(`cannot load settings from url ${this.configUrl}`, e);
    }
    return this.settings;
  }
  static {
    this.ɵfac = function CustomConfigurationService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CustomConfigurationService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(CUSTOM_APP_CONFIG), i0.ɵɵinject(CUSTOM_CONFIG_URL));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CustomConfigurationService,
      factory: CustomConfigurationService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomConfigurationService, [{
    type: Injectable
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CUSTOM_APP_CONFIG]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CUSTOM_CONFIG_URL]
    }]
  }], null);
})();
class ConfigurationModule {
  static forRoot() {
    return {
      ngModule: ConfigurationModule,
      providers: [ConfigurationService, CustomConfigurationService]
    };
  }
  static {
    this.ɵfac = function ConfigurationModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ConfigurationModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ConfigurationModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideHttpClient(withInterceptorsFromDi())],
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConfigurationModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [CommonModule],
      bootstrap: [],
      providers: [provideHttpClient(withInterceptorsFromDi())]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { APP_CONFIG, CONFIG_URL, CUSTOM_APP_CONFIG, CUSTOM_CONFIG_URL, ConfigurationModule, ConfigurationService, CustomConfigurationService };
