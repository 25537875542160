import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import { UAParser } from 'ua-parser-js';
import * as i1 from 'ngx-cookie-service';
import { CookieService } from 'ngx-cookie-service';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
function UnsupportedBrowserWarningComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2)(2, "h6");
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(3, "svg", 3);
    i0.ɵɵelement(4, "path", 4);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(5, " Your Browser is unsupported! ");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(6, " To ensure that you can take advantage of the full AVIATAR experience, you require a state-of-the-art browser. Please install the latest version of Google Chrome. ");
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(7, "button", 5);
    i0.ɵɵlistener("click", function UnsupportedBrowserWarningComponent_div_0_Template_button_click_7_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.userClosedBanner());
    });
    i0.ɵɵelementEnd()();
  }
}
class UnsupportedBrowserWarningComponent {
  constructor(cookieService) {
    this.cookieService = cookieService;
    // to display it always inside the storybook
    this.displayAlways = false;
    this.browserSupportList = {
      Chrome: {
        major: 118
      },
      // Includes Chrome for mobile devices
      Edge: {
        major: 118
      },
      Safari: {
        major: 16,
        minor: 1
      },
      "Mobile Safari": {
        major: 16,
        minor: 1
      },
      Firefox: {
        major: 115
      }
    };
    this.displayBlocker = false;
  }
  ngOnInit() {
    const parser = new UAParser(window.navigator.userAgent);
    this.userAgentData = parser.getResult();
    this.displayBlocker = this.displayAlways || !this.browserBlockerClosedRecently() && this.isBrowserOutOfDate();
  }
  isBrowserOutOfDate() {
    if (this.isBrowserUnsupported()) {
      return true;
    }
    const minMajorVersion = this.browserSupportList[this.userAgentData.browser.name].major;
    const minMinorVersion = this.browserSupportList[this.userAgentData.browser.name].minor;
    if (minMajorVersion && parseInt(this.userAgentData.browser.major) < minMajorVersion) {
      return true;
    } else if (minMajorVersion && parseInt(this.userAgentData.browser.major) == minMajorVersion) {
      const browserMinorVersion = this.parseMinorVersion(this.userAgentData.browser.version);
      if (minMinorVersion && browserMinorVersion < minMinorVersion) {
        return true;
      }
    }
  }
  isBrowserUnsupported() {
    return !Object.keys(this.browserSupportList).includes(this.userAgentData.browser.name);
  }
  parseMinorVersion(version) {
    return parseInt(version.replace(/[^\d.]/g, '').split(".")[1]);
  }
  browserBlockerClosedRecently() {
    return this.cookieService.get('unsupportedBrowserWarningClosed') === 'true';
  }
  userClosedBanner() {
    this.cookieService.set('unsupportedBrowserWarningClosed', 'true', 30, '/', 'aviatar.io');
    this.displayBlocker = false;
  }
  static {
    this.ɵfac = function UnsupportedBrowserWarningComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UnsupportedBrowserWarningComponent)(i0.ɵɵdirectiveInject(i1.CookieService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: UnsupportedBrowserWarningComponent,
      selectors: [["aviatar-unsupported-browser-warning"]],
      inputs: {
        displayAlways: "displayAlways"
      },
      decls: 1,
      vars: 1,
      consts: [["class", "unsupported-browser-warning", 4, "ngIf"], [1, "unsupported-browser-warning"], [1, "alert-content"], ["xmlns", "http://www.w3.org/2000/svg", "viewBox", "0 0 24 24", "width", "1em", "fill", "currentColor", 2, "vertical-align", "-0.125em"], ["d", "M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16"], ["type", "button", 1, "btn-close", 3, "click"]],
      template: function UnsupportedBrowserWarningComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, UnsupportedBrowserWarningComponent_div_0_Template, 8, 0, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.displayBlocker);
        }
      },
      dependencies: [i2.NgIf],
      styles: [".unsupported-browser-warning[_ngcontent-%COMP%]{position:absolute;z-index:1081;bottom:0;left:0;right:0;background-color:#f2dddd;color:#4c2223;padding:1rem;box-shadow:0 2px 8px #00000026}.unsupported-browser-warning[_ngcontent-%COMP%]   .btn-close[_ngcontent-%COMP%]{position:absolute;top:0;right:0;padding:1.25rem 1rem}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UnsupportedBrowserWarningComponent, [{
    type: Component,
    args: [{
      selector: 'aviatar-unsupported-browser-warning',
      template: "<div class=\"unsupported-browser-warning\" *ngIf=\"displayBlocker\">\n  <div class=\"alert-content\">\n    <h6>\n      <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"1em\" fill=\"currentColor\" style=\"vertical-align: -0.125em\">\n        <path d=\"M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16\"/>\n      </svg>\n      Your Browser is unsupported!\n    </h6>\n    To ensure that you can take advantage of the full AVIATAR experience, you require a state-of-the-art browser. Please install the latest version of Google Chrome.\n  </div>\n  <button type=\"button\" class=\"btn-close\" (click)=\"userClosedBanner()\"></button>\n</div>\n",
      styles: [".unsupported-browser-warning{position:absolute;z-index:1081;bottom:0;left:0;right:0;background-color:#f2dddd;color:#4c2223;padding:1rem;box-shadow:0 2px 8px #00000026}.unsupported-browser-warning .btn-close{position:absolute;top:0;right:0;padding:1.25rem 1rem}\n"]
    }]
  }], () => [{
    type: i1.CookieService
  }], {
    displayAlways: [{
      type: Input
    }]
  });
})();
class UnsupportedBrowserWarningModule {
  static {
    this.ɵfac = function UnsupportedBrowserWarningModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || UnsupportedBrowserWarningModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: UnsupportedBrowserWarningModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [CookieService],
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UnsupportedBrowserWarningModule, [{
    type: NgModule,
    args: [{
      declarations: [UnsupportedBrowserWarningComponent],
      imports: [CommonModule],
      providers: [CookieService],
      exports: [UnsupportedBrowserWarningComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of unsupported-browser-warning
 */

/**
 * Generated bundle index. Do not edit.
 */

export { UnsupportedBrowserWarningComponent, UnsupportedBrowserWarningModule };
